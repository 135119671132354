import { assign } from 'xstate';
import { CartErrorDisplay, CartInstanceResponse } from '../../../../../business-logic/models/Cart';
import { ReviewCoverSelection } from '../../../../../business-logic/models/ReviewCoverSelection';
import { ReviewCoverMachineContextTypes } from '../context/reviewCoverMachineContext';
import { Typegen0 } from '../review-cover-machine/reviewCoverMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setCoversToDisplay = assign<
    ReviewCoverMachineContextTypes,
    { type: EventsCausingActions['setCoversToDisplay']; data: CartInstanceResponse }
>({
    cartItemsByCoverCode: (ctx, event) => {
        const cartResponse = event.data;
        const allProducts = ctx.products.map((x) => {
            const pdsVersion = `${x?.pdsVersionMajor}.${x?.pdsVersionMinor}`;
            const selectedCoverInformation = ctx.coverInformation![x.productSpec.mainCoverType.coverCode][pdsVersion];
            return {
                coverCode: x.productSpec.mainCoverType.coverCode,
                price: x.productSpec.mainCoverType.billing.premium,
                coverType: x.productSpec.mainCoverTypeId,
                selectedCoverInformation,
            };
        });

        const baseObject = allProducts.map((x) => {
            return { [x.coverCode]: [] };
        });

        const parseCoversFromCart =
            cartResponse?.coverItems.reduce<Record<string, ReviewCoverSelection[]>>(
                (acc, cur) => {
                    const productConfig = allProducts.find((x) => x.coverCode === cur.coverCode);
                    const productOptionConfig = ctx.productGroups.find((y) =>
                        y.options.find((x) => x.coverCodes.includes(cur.coverCode)),
                    );

                    if (!productConfig || !productOptionConfig) return acc;

                    acc[cur.coverCode].push({
                        selectedCover: cur.coverCode,
                        coverStartDate: cur.startTime,
                        personId: cur.insuredPersonId,
                        timezone: ctx.purchaseState?.destination?.timezone ?? null,
                        insurancePolicyId: cur.insurancePolicyId,
                        insuranceCoverId: cur.insuranceCoverId,
                        insuranceProductId: cur.insuranceProductId,
                        unitPrice: productConfig.price,
                        ...(ctx.purchaseState?.destination && {
                            destination: {
                                destinations: ctx.purchaseState.destination.destinations,
                                startingRegion: {
                                    region: ctx.purchaseState.destination.startingRegion,
                                    timeZone: ctx.purchaseState.destination.timezone,
                                },
                            },
                        }),
                    });
                    return acc;
                },
                Object.assign({}, ...baseObject),
            ) ?? [];

        return Object.entries(parseCoversFromCart)
            .filter(([, covers]) => !!covers.length)
            .map(([coverCode, covers]) => {
                const productConfig = allProducts.find((x) => x.coverCode === coverCode);
                const productOptionConfig = ctx.productGroups
                    .find((y) => y.options.find((x) => x.coverCodes.includes(coverCode)))
                    ?.options.find((y) => y.coverCodes.includes(coverCode));
                const subTotalByCoverCode = covers.reduce((acc, cur) => acc + cur.unitPrice!, 0);
                return {
                    selectedCoverCode: coverCode,
                    name: productConfig?.selectedCoverInformation.name ?? '',
                    checkoutChipText: productConfig?.selectedCoverInformation?.checkoutChipText ?? '',
                    subTotalByCoverCode,
                    unitPrice: productConfig?.price ?? 0,
                    group: productOptionConfig?.id ?? '',
                    coverItems: covers,
                    unitLabel: productConfig?.selectedCoverInformation?.datePickerContent.unitLabel ?? '',
                    hideUnitCount: productConfig?.selectedCoverInformation?.datePickerContent.hideUnitLabel,
                    coverType: productConfig?.coverType ?? '',
                    paymentModelDescription: productOptionConfig?.paymentModelDescription ?? '',
                };
            });
    },
}) as any;

export const setCartTotal = assign<
    ReviewCoverMachineContextTypes,
    { type: EventsCausingActions['setCartTotal']; data: CartInstanceResponse }
>({
    cartTotal: (ctx) => ctx.cartItemsByCoverCode.reduce((acc, cur) => acc + cur.subTotalByCoverCode, 0),
}) as any;

export const setCartError = assign<
    ReviewCoverMachineContextTypes,
    { type: 'CART_CREATE_ERROR'; data: CartErrorDisplay }
>({
    cartError: (ctx, event) => event.data,
}) as any;

export const resetCartError = assign<ReviewCoverMachineContextTypes, { type: EventsCausingActions['resetCartError'] }>({
    cartError: () => null,
}) as any;
