import { ActorRefFrom, assign, createMachine, send, spawn } from 'xstate';
import Analytics from '../../../analytics/Analytics';
import { CartErrorDisplay, CartInstanceResponse, CartResponseItem } from '../../../business-logic/models/Cart';
import { AllCoverInformation } from '../../../business-logic/models/cdn-content/CoverInformation';
import {
    CheckoutCoverResponse,
    CheckoutDetailsResponse,
    CoverResponse,
} from '../../../business-logic/models/CheckoutDetails';
import ProductGroupsResponse from '../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import { ReviewCoverSelection } from '../../../business-logic/models/ReviewCoverSelection';
import { UserPaymentMethod } from '../../../business-logic/models/UserPaymentMethod';
import paymentElementMachine from '../../../components/payment-methods/payment-element/payment-element-machine/paymentElementMachine';
import AnalyticsMetadataService from '../../../services/analytics-metadata-service/AnalyticsMetadataService';
import PaymentService from '../../../services/payment-service/PaymentService';
import common from '../../../strings/common';
import payments from '../../../strings/payments';
import { PurchaseState } from '../../../types/PurchaseState';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import DateFormat from '../../../utils/constants/DateFormat';
import formatDateToString from '../../../utils/formatDateToString';

const checkoutMachine = createMachine(
    {
        id: 'CheckoutMachine',
        initial: 'init',
        tsTypes: {} as import('./checkoutMachine.typegen').Typegen0,
        schema: {
            context: {} as {
                accessToken: string;
                products: ProductResponse[];
                coverInformation: AllCoverInformation | null;
                productGroups: ProductGroupsResponse | null;
                discountCode: string;
                purchaseState: PurchaseState;
                discountErrorInfo: string;
                checkoutErrorInfo: string;
                checkoutDetails: CheckoutDetailsResponse;
                incomingCheckoutDetails: CheckoutDetailsResponse;
                covers: CheckoutCoverResponse[];
                coverSelections: ReviewCoverSelection[];
                paymentMethodMachine: ActorRefFrom<typeof paymentElementMachine>;
                paymentMethods: UserPaymentMethod[];
                fetchCreditBalance: () => void;
                selectedPaymentMethod: string;
                activities: string[];
                requireActivitiesMetadata: boolean;
            },
            events: {} as
                | { type: 'ENTER_DISCOUNT_CODE'; data: string }
                | { type: 'APPLY_DISCOUNT_CODE' }
                | { type: 'SETTING_UP_PAYMENT_METHOD' }
                | {
                      type: 'CART_CLOSE';
                      data: {
                          checkoutDetails: {
                              checkoutDetails: CartInstanceResponse;
                              covers: CartResponseItem[];
                          };
                      };
                  }
                | {
                      type: 'CART_CLOSE_ERROR';
                      data: CartErrorDisplay;
                  }
                | { type: 'GO_BACK' }
                | { type: 'CART_TRY_AGAIN' }
                | {
                      type: 'INITIATE_CHECKOUT';
                      data: { paymentMethodId?: string; setAsDefault?: boolean; selectedPaymentMethod?: string };
                  },
        },
        states: {
            awaitCheckoutDetailsFromCart: {
                entry: 'closeCart',
                tags: ['initialising'],
                on: {
                    CART_CLOSE: {
                        target: 'updateMetadata',
                        actions: ['setCheckoutDetails'],
                    },
                    CART_CLOSE_ERROR: {
                        target: 'displayCartError',
                    },
                },
            },
            updateMetadata: {
                tags: ['initialising'],
                invoke: {
                    src: 'setMetadata',
                    onDone: [
                        {
                            target: 'getPaymentMethods',
                        },
                    ],
                    onError: [
                        {
                            target: 'getPaymentMethods',
                        },
                    ],
                },
            },
            getPaymentMethods: {
                tags: ['initialising'],
                invoke: {
                    src: 'getPaymentMethods',
                    onDone: {
                        target: 'ready',
                        actions: ['setPaymentMethods', 'spawnPaymentMethodMachine'],
                    },
                    onError: {
                        target: 'displayErrorPage',
                    },
                },
            },
            init: {
                always: [
                    {
                        cond: 'isValidSelectedCover',
                        target: 'awaitCheckoutDetailsFromCart',
                    },
                    {
                        target: 'redirectToDashboard',
                    },
                ],
            },
            redirectToDashboard: {
                type: 'final',
                description: 'This is probably a case of somebody going directly to this URL',
            },
            ready: {
                type: 'parallel',
                states: {
                    discountCode: {
                        initial: 'idle',
                        states: {
                            idle: {
                                on: {
                                    ENTER_DISCOUNT_CODE: [
                                        {
                                            cond: 'isDiscountCodeValid',
                                            actions: 'setDiscountCode',
                                            target: 'readyToApplyDiscountCode',
                                        },
                                        {
                                            actions: 'setDiscountCode',
                                            target: 'idle',
                                        },
                                    ],
                                },
                            },
                            readyToApplyDiscountCode: {
                                on: {
                                    ENTER_DISCOUNT_CODE: [
                                        {
                                            cond: 'isDiscountCodeValid',
                                            actions: 'setDiscountCode',
                                            target: 'readyToApplyDiscountCode',
                                        },
                                        {
                                            actions: 'setDiscountCode',
                                            target: 'idle',
                                        },
                                    ],
                                    APPLY_DISCOUNT_CODE: {
                                        target: 'applyDiscount',
                                    },
                                },
                            },
                            applyDiscount: {
                                entry: 'trackCouponEntered',
                                invoke: {
                                    src: 'applyPromoCode',
                                    onDone: {
                                        actions: ['setIncomingCheckoutDetails', 'trackCouponApplied'],
                                        target: 'displayDiscountSuccess',
                                    },
                                    onError: {
                                        actions: ['setDiscountErrorInfo', 'trackCouponDenied'],
                                        target: 'displayDiscountError',
                                    },
                                },
                            },
                            displayDiscountSuccess: {
                                after: {
                                    2000: {
                                        actions: ['updateCheckoutDetails', 'clearDiscountCode'],
                                        target: 'idle',
                                    },
                                },
                            },
                            displayDiscountError: {
                                on: {
                                    ENTER_DISCOUNT_CODE: [
                                        {
                                            cond: 'isDiscountCodeValid',
                                            actions: ['setDiscountCode', 'clearDiscountErrorInfo'],
                                            target: 'readyToApplyDiscountCode',
                                        },
                                        {
                                            actions: ['setDiscountCode', 'clearDiscountErrorInfo'],

                                            target: 'idle',
                                        },
                                    ],
                                    APPLY_DISCOUNT_CODE: {
                                        target: 'applyDiscount',
                                    },
                                },
                            },
                        },
                    },
                    checkout: {
                        initial: 'idle',
                        states: {
                            idle: {
                                on: {
                                    INITIATE_CHECKOUT: {
                                        actions: 'setSelectedPaymentMethod',
                                        target: 'initiateCheckout',
                                    },
                                },
                            },
                            initiateCheckout: {
                                invoke: {
                                    src: 'checkout',
                                    onDone: {
                                        target: 'showCheckoutSuccess',
                                    },
                                    onError: {
                                        actions: 'setCheckoutErrorInfo',
                                        target: 'displayCheckoutError',
                                    },
                                },
                            },
                            displayCheckoutError: {
                                entry: 'sendCheckoutErrorEvent',
                                on: {
                                    INITIATE_CHECKOUT: {
                                        actions: 'setSelectedPaymentMethod',
                                        target: 'initiateCheckout',
                                    },
                                    SETTING_UP_PAYMENT_METHOD: {
                                        target: 'idle',
                                    },
                                },
                            },
                            showCheckoutSuccess: {
                                entry: [
                                    'sendCheckoutSuccessEvent',
                                    'fetchNewCreditBalance',
                                    'trackCheckoutCompleted',
                                    'ldTrackCheckoutCompleted',
                                ],
                                after: {
                                    2000: {
                                        target: 'successRedirect',
                                    },
                                },
                            },
                            successRedirect: {
                                type: 'final',
                                entry: 'redirectToSuccessPage',
                            },
                        },
                    },
                },
            },
            displayErrorPage: {
                type: 'final',
            },
            displayCartError: {
                on: {
                    CART_TRY_AGAIN: {
                        target: 'awaitCheckoutDetailsFromCart',
                    },
                    GO_BACK: {
                        actions: 'goBack',
                    },
                },
            },
        },
    },
    {
        guards: {
            isValidSelectedCover: (ctx) => {
                return !!ctx.purchaseState.selectedProductOption;
            },
            isDiscountCodeValid: (ctx, event) => {
                // Following existing Stripe behaviour
                return event.data.length > 0;
            },
        },
        services: {
            getPaymentMethods: async (ctx) => {
                const paymentMethods = await PaymentService.getPaymentMethods({ accessToken: ctx.accessToken });
                return {
                    paymentMethods,
                };
            },
            applyPromoCode: (ctx) => {
                const applyPromoCodeBody = {
                    accessToken: ctx.accessToken,
                    paymentId: ctx.checkoutDetails.paymentId,
                    promoCode: ctx.discountCode,
                };

                return PaymentService.applyPromoCode(applyPromoCodeBody);
            },
            checkout: (ctx, event) => {
                const checkoutBody = {
                    accessToken: ctx.accessToken,
                    paymentId: ctx.checkoutDetails.paymentId,
                    paymentMethodId: event.data.paymentMethodId,
                    setAsDefault: event.data.setAsDefault,
                };

                return PaymentService.payCheckout(checkoutBody);
            },
            setMetadata: async (ctx) => {
                if (ctx.requireActivitiesMetadata) {
                    await AnalyticsMetadataService.updateActivitiesMetadata({
                        accessToken: ctx.accessToken,
                        activityMetadata: {
                            paymentId: ctx.checkoutDetails.paymentId,
                            activities: ctx.activities,
                        },
                    });
                }
            },
        },
        actions: {
            setCheckoutDetails: assign({
                checkoutDetails: (ctx, event: any) => {
                    return event.data.checkoutDetails.checkoutDetails as CheckoutDetailsResponse;
                },
                covers: (ctx, event: any) => {
                    const newCovers = (event.data.checkoutDetails.covers as CoverResponse[]).map((cover) => {
                        const correspondingProductOnSale = ctx.products.find(
                            (p) => p.productSpec.mainCoverType.coverCode === cover.coverCode,
                        );
                        const pdsVersion = `${correspondingProductOnSale?.pdsVersionMajor}.${correspondingProductOnSale?.pdsVersionMinor}`;
                        const selectedCoverInformation = ctx.coverInformation![cover.coverCode!][pdsVersion];
                        return {
                            ...cover,
                            coverName: selectedCoverInformation.name || cover.coverCode,
                        };
                    });
                    return newCovers as CheckoutCoverResponse[];
                },
            }),
            setPaymentMethods: assign({
                paymentMethods: (ctx, event: any) => {
                    return event.data.paymentMethods as UserPaymentMethod[];
                },
            }),
            setIncomingCheckoutDetails: assign({
                incomingCheckoutDetails: (ctx, event) => event.data as CheckoutDetailsResponse,
            }),
            updateCheckoutDetails: assign({
                checkoutDetails: (ctx) => ctx.incomingCheckoutDetails,
            }),
            setDiscountCode: assign({
                discountCode: (ctx, event) => event.data.toUpperCase(),
            }),
            setSelectedPaymentMethod: assign({
                selectedPaymentMethod: (ctx, event) => event.data.selectedPaymentMethod || 'card',
            }),
            // below eslint-disable due to this documented bug https://xstate.js.org/docs/guides/typescript.html#troubleshooting
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            clearDiscountCode: assign({ discountCode: (ctx) => '' }),
            setDiscountErrorInfo: assign({
                discountErrorInfo: (ctx, event: any) => {
                    const errorInfo = event.data.response.data.message as string;
                    return errorInfo.charAt(0).toUpperCase() + errorInfo.slice(1);
                },
            }),
            // below eslint-disable due to this documented bug https://xstate.js.org/docs/guides/typescript.html#troubleshooting
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            clearDiscountErrorInfo: assign({ discountErrorInfo: (ctx) => '' }),
            setCheckoutErrorInfo: assign({
                checkoutErrorInfo: (ctx, event: any) => {
                    const failedReason: string = event?.data?.response?.data?.message;
                    if (failedReason) {
                        const parsedError = failedReason.match(/(.*?): (.*)/);
                        if (parsedError) {
                            // Is stripe error
                            const [, stripeErrorCode, stripeErrorMessage] = parsedError;
                            if (stripeErrorCode === 'invoice_payment_intent_requires_action') {
                                return payments.errorNotSupportedCard;
                            }
                            return stripeErrorMessage;
                        }
                    }
                    return common.errorSomethingWentWrongTryAgain;
                },
            }),
            spawnPaymentMethodMachine: assign({
                paymentMethodMachine: (ctx) => {
                    const selectedProductGroup = ctx.productGroups?.find(
                        (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
                    );
                    const coverCodes = selectedProductGroup?.options.find((x) =>
                        x.coverCodes.includes(ctx.purchaseState.selectedProductOption?.representedByCoverCode ?? ''),
                    )?.coverCodes;

                    return spawn(
                        paymentElementMachine.withContext({
                            ...paymentElementMachine.context,
                            defaultCard: ctx.paymentMethods.find((pm) => pm.isDefault && pm.type === 'card'),
                            accessToken: ctx.accessToken,
                            hasSubscriptionInCheckout:
                                coverCodes?.some(
                                    (c) =>
                                        ctx.products.find((x) => x.productSpec.mainCoverType.coverCode === c)
                                            ?.productSpec.mainCoverTypeId === CoverTypeId.SUBSCRIPTION_V1,
                                ) ?? false,
                            paymentId: ctx.checkoutDetails.paymentId,
                        }),
                    );
                },
            }),
            sendCheckoutSuccessEvent: send('CHECKOUT_SUCCESS', { to: (ctx) => ctx.paymentMethodMachine }),
            sendCheckoutErrorEvent: send('CHECKOUT_ERROR', { to: (ctx) => ctx.paymentMethodMachine }),
            fetchNewCreditBalance: (ctx) => ctx.fetchCreditBalance(),

            // *******************
            // Analytics
            // *******************
            trackCouponEntered: (ctx) => Analytics.trackCouponEntered(ctx.checkoutDetails.paymentId, ctx.discountCode),
            trackCouponApplied: (ctx, event) =>
                Analytics.trackCouponApplied(
                    ctx.checkoutDetails.paymentId,
                    (event.data as CheckoutDetailsResponse).invoice.couponName,
                    (event.data as CheckoutDetailsResponse).invoice.couponAmountOff,
                ),
            trackCouponDenied: (ctx, event: any) =>
                Analytics.trackCouponDenied(
                    ctx.checkoutDetails.paymentId,
                    ctx.discountCode,
                    event.data.response.data.message as string,
                ),
            trackCheckoutCompleted: (ctx) => {
                const selectedProductGroup = ctx.productGroups?.find(
                    (group) => group.id.toLowerCase() === ctx.purchaseState?.selectedProductGrouping?.toLowerCase(),
                );
                const selectedCoverCodes = selectedProductGroup?.options.find((x) =>
                    x.coverCodes.includes(ctx.purchaseState.selectedProductOption?.representedByCoverCode ?? ''),
                )?.coverCodes;
                Analytics.trackOrderCompleted({
                    order_id: ctx.checkoutDetails.paymentId,
                    total: ctx.checkoutDetails.invoice.amountDue,
                    discount:
                        ctx.checkoutDetails.invoice.couponAmountOff > 0
                            ? ctx.checkoutDetails.invoice.couponAmountOff
                            : undefined,
                    coupon: ctx.checkoutDetails.coupon?.promoCode,
                    products:
                        selectedCoverCodes?.map((c) => {
                            const correspondingProductOnSale = ctx.products.find(
                                (x) => x.productSpec.mainCoverType.coverCode === c,
                            );
                            const requiredInsuredPersonRelationship =
                                correspondingProductOnSale?.productSpec.mainCoverType.requiredInsuredPersonRelationship;
                            const pdsVersion = `${correspondingProductOnSale?.pdsVersionMajor}.${correspondingProductOnSale?.pdsVersionMinor}`;
                            const selectedCoverInformation = ctx.coverInformation![c][pdsVersion];
                            return {
                                coverCode: c,
                                purchaseFor: requiredInsuredPersonRelationship ?? 'myself',
                                quantity: selectedCoverCodes.filter((x) => x === c).length,
                                variant: ctx.purchaseState.coverStartDates.map((startDate) =>
                                    formatDateToString(startDate, DateFormat.ANALYTICS),
                                ),
                                consumerFacingProductName: selectedCoverInformation.analytics.consumerFacingProductName,
                                duration: selectedCoverInformation.analytics.duration,
                            };
                        }) ?? [],
                });
            },
        },
    },
);

export default checkoutMachine;
