import { createMachine } from 'xstate';
import {
    clearDobError,
    setDate,
    setFirstName,
    setInvalidDateFormatError,
    setLastName,
    setMaxAgeError,
    setMinAgeError,
    setMonth,
    setYear,
    setEmail,
    setEmailError,
    setPurchaserPrivacyCheck,
    setInsuredPersonPrivacyCheck,
    setResidencyCheck,
} from './actions/adultDetailsFormMachineActions';
import { AdultDetailsFormMachineContext, initialContext } from './context/adultDetailsFormMachineContext';
import {
    isAboveMaxAge,
    isBelowMinAge,
    isDateFilledIn,
    isFirstNameValid,
    isLastNameValid,
    isValidDateFormat,
    isEmailValid,
    isEmailFilledIn,
    isPermissionCheckValid,
    isSharingCheckValid,
    isResidencyCheckValid,
} from './guards/adultDetailsFormMachineGuards';

const adultDetailsFormMachine = createMachine(
    {
        id: 'AdultDetailsFormMachine',
        type: 'parallel',
        tsTypes: {} as import('./adultDetailsFormMachine.typegen').Typegen0,
        schema: {
            context: {} as AdultDetailsFormMachineContext,
            events: {} as
                | { type: 'UPDATE_FIRST_NAME'; data: string }
                | { type: 'UPDATE_LAST_NAME'; data: string }
                | { type: 'UPDATE_DATE'; data: string }
                | { type: 'UPDATE_MONTH'; data: string }
                | { type: 'UPDATE_YEAR'; data: string }
                | { type: 'BLUR_YEAR' }
                | { type: 'UPDATE_EMAIL'; data: string }
                | { type: 'BLUR_EMAIL' }
                | { type: 'UPDATE_PURCHASER_PRIVACY_CHECK'; data: boolean }
                | { type: 'UPDATE_RESIDENCY_CHECK'; data: boolean }
                | { type: 'UPDATE_INSURED_PERSON_PRIVACY_CHECK'; data: boolean },
        },
        context: initialContext,
        states: {
            firstName: {
                initial: 'validateFirstName',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_FIRST_NAME: {
                                actions: 'setFirstName',
                                target: 'validateFirstName',
                            },
                        },
                    },
                    valid: {
                        on: {
                            UPDATE_FIRST_NAME: {
                                actions: 'setFirstName',
                                target: 'validateFirstName',
                            },
                        },
                    },
                    validateFirstName: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isFirstNameValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                },
            },
            lastName: {
                initial: 'validateLastName',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_LAST_NAME: {
                                actions: 'setLastName',
                                target: 'validateLastName',
                            },
                        },
                    },
                    validateLastName: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isLastNameValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_LAST_NAME: {
                                actions: 'setLastName',
                                target: 'validateLastName',
                            },
                        },
                    },
                },
            },
            dob: {
                initial: 'validateIfDateFilledIn',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_DATE: {
                                actions: 'setDate',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_MONTH: {
                                actions: 'setMonth',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_YEAR: {
                                actions: 'setYear',
                                target: 'validateIfDateFilledIn',
                            },
                            BLUR_YEAR: {
                                target: 'validateIfDateFilledIn',
                            },
                        },
                    },
                    validateIfDateFilledIn: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isDateFilledIn',
                                target: 'validateDateFormat',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    validateDateFormat: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isValidDateFormat',
                                target: 'validateAge',
                            },
                            {
                                actions: 'setInvalidDateFormatError',
                                target: 'displayDobError',
                            },
                        ],
                    },
                    displayDobError: {
                        tags: ['INVALID'],
                        exit: 'clearDobError',
                        on: {
                            UPDATE_DATE: {
                                actions: 'setDate',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_MONTH: {
                                actions: 'setMonth',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_YEAR: {
                                actions: 'setYear',
                                target: 'validateIfDateFilledIn',
                            },
                            BLUR_YEAR: {
                                target: 'validateIfDateFilledIn',
                            },
                        },
                    },
                    validateAge: {
                        tags: ['INVALID'],
                        always: [
                            {
                                actions: 'setMinAgeError',
                                cond: 'isBelowMinAge',
                                target: 'displayDobError',
                            },
                            {
                                actions: 'setMaxAgeError',
                                cond: 'isAboveMaxAge',
                                target: 'displayDobError',
                            },
                            {
                                target: 'valid',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_DATE: {
                                actions: 'setDate',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_MONTH: {
                                actions: 'setMonth',
                                target: 'validateIfDateFilledIn',
                            },
                            UPDATE_YEAR: {
                                actions: 'setYear',
                                target: 'validateIfDateFilledIn',
                            },
                            BLUR_YEAR: {
                                target: 'validateIfDateFilledIn',
                            },
                        },
                    },
                },
            },
            email: {
                initial: 'validateIfEmailFilledIn',
                states: {
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_EMAIL: {
                                actions: 'setEmail',
                            },
                            BLUR_EMAIL: {
                                target: 'validateIfEmailFilledIn',
                            },
                        },
                    },
                    valid: {
                        on: {
                            UPDATE_EMAIL: {
                                actions: 'setEmail',
                                target: 'idle',
                            },
                            BLUR_EMAIL: {
                                target: 'validateIfEmailFilledIn',
                            },
                        },
                    },
                    validateIfEmailFilledIn: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isEmailFilledIn',
                                target: 'validateEmail',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    validateEmail: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isEmailValid',
                                target: 'valid',
                            },
                            {
                                actions: 'setEmailError',
                                target: 'displayEmailError',
                            },
                        ],
                    },
                    displayEmailError: {
                        tags: ['INVALID'],
                        exit: 'clearEmailError',
                        on: {
                            UPDATE_EMAIL: {
                                actions: 'setEmail',
                                target: 'idle',
                            },
                            BLUR_EMAIL: {
                                target: 'validateIfEmailFilledIn',
                            },
                        },
                    },
                },
            },
            purchaserPrivacyCheck: {
                initial: 'validatePermissionCheck',
                states: {
                    validatePermissionCheck: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isPermissionCheckValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_PURCHASER_PRIVACY_CHECK: {
                                actions: 'setPurchaserPrivacyCheck',
                                target: 'validatePermissionCheck',
                            },
                        },
                    },
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_PURCHASER_PRIVACY_CHECK: {
                                actions: 'setPurchaserPrivacyCheck',
                                target: 'validatePermissionCheck',
                            },
                        },
                    },
                },
            },
            residencyCheck: {
                initial: 'validateResidencyCheck',
                states: {
                    validateResidencyCheck: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isResidencyCheckValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_RESIDENCY_CHECK: {
                                actions: 'setResidencyCheck',
                                target: 'validateResidencyCheck',
                            },
                        },
                    },
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_RESIDENCY_CHECK: {
                                actions: 'setResidencyCheck',
                                target: 'validateResidencyCheck',
                            },
                        },
                    },
                },
            },
            insuredPersonPrivacyCheck: {
                initial: 'validateSharingCheck',
                states: {
                    validateSharingCheck: {
                        tags: ['INVALID'],
                        always: [
                            {
                                cond: 'isSharingCheckValid',
                                target: 'valid',
                            },
                            {
                                target: 'idle',
                            },
                        ],
                    },
                    valid: {
                        on: {
                            UPDATE_INSURED_PERSON_PRIVACY_CHECK: {
                                actions: 'setInsuredPersonPrivacyCheck',
                                target: 'validateSharingCheck',
                            },
                        },
                    },
                    idle: {
                        tags: ['INVALID'],
                        on: {
                            UPDATE_INSURED_PERSON_PRIVACY_CHECK: {
                                actions: 'setInsuredPersonPrivacyCheck',
                                target: 'validateSharingCheck',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        guards: {
            isFirstNameValid,
            isLastNameValid,
            isDateFilledIn,
            isValidDateFormat,
            isAboveMaxAge,
            isBelowMinAge,
            isEmailValid,
            isEmailFilledIn,
            isPermissionCheckValid,
            isResidencyCheckValid,
            isSharingCheckValid,
        },
        actions: {
            setFirstName,
            setLastName,
            setDate,
            setMonth,
            setYear,
            setMinAgeError,
            setMaxAgeError,
            setInvalidDateFormatError,
            clearDobError,
            setEmail,
            setEmailError,
            setPurchaserPrivacyCheck,
            setResidencyCheck,
            setInsuredPersonPrivacyCheck,
        },
    },
);

export default adultDetailsFormMachine;
