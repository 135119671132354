import { FC, useRef, useState } from 'react';
import classNames from 'classnames';
import navyCircleTick from '../../../../assets/images/check-white-tick-blue-circle.svg';
import blueChevron from '../../../../assets/images/icon-chevron-down-blue.svg';
import personIcon from '../../../../assets/images/icon-person.svg';
import { useUser } from '../../../../business-logic/context-provider/user-context/UserContext';

import './ManageCoverPersonSelector.scss';

interface ManageCoverPersonSelectorProps {
    onSelectPerson: (personId: string) => void;
    selectedPersonId: string;
}

const ManageCoverPersonSelector: FC<ManageCoverPersonSelectorProps> = ({ selectedPersonId, onSelectPerson }) => {
    const { insurablePersons, userDetails } = useUser();
    const [isExpanded, setIsExpanded] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="manage-cover-v3__selector">
            <button
                type="button"
                disabled={insurablePersons.length === 0}
                className="manage-cover-v3__selector__expander"
                onClick={() => {
                    if (insurablePersons.length > 0) {
                        toggleExpand();
                    }
                }}
                onKeyDown={() => {
                    onSelectPerson(userDetails.personId!);
                }}
            >
                <div className="manage-cover-v3__selector__expander__label">
                    <img src={personIcon} alt="" className="manage-cover-v3__selector__expander__icon" />
                    <span>
                        {selectedPersonId === userDetails.personId!
                            ? 'Your'
                            : `${insurablePersons.find((x) => x.personId === selectedPersonId)?.firstName}'s`}{' '}
                        cover
                    </span>
                </div>
                {insurablePersons.length > 0 && (
                    <div className="manage-cover-v3__selector__expander__action">
                        <span className="manage-cover-v3__selector__expander__action__label">Filter</span>
                        <img
                            src={blueChevron}
                            className={classNames(
                                'manage-cover-v3__selector__expander__action__icon',
                                isExpanded && 'manage-cover-v3__selector__expander__action__icon--selected',
                            )}
                            alt=""
                        />
                    </div>
                )}
            </button>
            {!!insurablePersons.length && (
                <div
                    className={classNames('manage-cover-v3__selector__list')}
                    ref={listRef}
                    style={{
                        maxHeight: isExpanded ? `${listRef?.current?.scrollHeight}px` : '0px',
                    }}
                >
                    <hr className="manage-cover-v3__selector__divider" />
                    <button
                        type="button"
                        className={classNames(
                            'manage-cover-v3__selector__option',
                            selectedPersonId === userDetails.personId &&
                                'manage-cover-v3__selector__option__label--selected',
                        )}
                        onClick={() => {
                            onSelectPerson(userDetails.personId!);
                            toggleExpand();
                        }}
                    >
                        {selectedPersonId === userDetails.personId! ? (
                            <img src={navyCircleTick} alt="" className="manage-cover-v3__selector__option__icon" />
                        ) : (
                            <div className="manage-cover-v3__selector__option__icon--empty" />
                        )}
                        <span className="manage-cover-v3__selector__option__label">Your cover</span>
                    </button>
                    {insurablePersons.map((i) => (
                        <div key={i.personId}>
                            <button
                                type="button"
                                className={classNames(
                                    'manage-cover-v3__selector__option',
                                    selectedPersonId === i.personId &&
                                        'manage-cover-v3__selector__option__label--selected',
                                )}
                                onClick={() => {
                                    onSelectPerson(i.personId!);
                                    toggleExpand();
                                }}
                            >
                                {selectedPersonId === i.personId! ? (
                                    <img
                                        src={navyCircleTick}
                                        alt=""
                                        className="manage-cover-v3__selector__option__icon"
                                    />
                                ) : (
                                    <div className="manage-cover-v3__selector__option__icon--empty" />
                                )}
                                <span
                                    className={classNames(
                                        'manage-cover-v3__selector__option__label',
                                        selectedPersonId === i.personId &&
                                            'manage-cover-v3__selector__option--selected',
                                    )}
                                >
                                    {`${i.firstName}'s`} cover
                                </span>
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ManageCoverPersonSelector;
