import UserDetails from '../../../../../business-logic/models/UserDetails';
import { RadioCheckboxProps } from '../../../../../components/form/RadioCheckboxProps';
import ageCheck from './ageCheck';

const toDependantCheckboxProps = (
    dependant: UserDetails,
    coverStartDates: string[],
    ageEligibilityErrorMessage: string,
): Omit<RadioCheckboxProps, 'checked' | 'onChange'> => {
    const dependantAgeCheck = ageCheck({
        isAdult: false,
        dob: dependant.dob!,
        coverStartDates,
        ageEligibilityErrorMessage,
    });
    return {
        id: dependant.personId!,
        name: `${dependant.firstName} ${dependant.lastName}}`,
        label: `${dependant.firstName} ${dependant.lastName}`,
        disabled: !dependantAgeCheck.eligible,
        showTooltip: !dependantAgeCheck.eligible,
        modalContent: !dependantAgeCheck.eligible ? dependantAgeCheck.errorMessage : undefined,
    };
};

export default toDependantCheckboxProps;
