import UserDetails from '../../../../../business-logic/models/UserDetails';
import { RadioCheckboxProps } from '../../../../../components/form/RadioCheckboxProps';
import ageCheck from './ageCheck';

const toAccountHolderCheckboxProps = (
    accountHolderDetails: UserDetails,
    coverStartDates: string[],
    ageEligibilityErrorMessage: string,
    checkboxLabel: string,
): Omit<RadioCheckboxProps, 'checked' | 'onChange'> => {
    const accountHolderAgeCheck = ageCheck({
        isAdult: true,
        dob: accountHolderDetails.dob!,
        coverStartDates,
        ageEligibilityErrorMessage,
    });
    return {
        id: accountHolderDetails.personId!,
        name: checkboxLabel,
        label: checkboxLabel,
        disabled: !accountHolderAgeCheck.eligible,
        showTooltip: !accountHolderAgeCheck.eligible,
        modalContent: !accountHolderAgeCheck.eligible ? accountHolderAgeCheck.errorMessage : undefined,
    };
};

export default toAccountHolderCheckboxProps;
