import { useOktaAuth } from '@okta/okta-react';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import OktaSigninWidget from '../../components/okta-signin-widget/OktaSigninWidget';
import login from '../../content/ui/screens/login/login';
import logo from '../../logo.svg';
import Routes from '../../utils/Routes';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import storePartnerEventSignUpCode from '../../utils/storePartnerEventSignUpCode';
import Analytics from '../../analytics/Analytics';

import './Login.scss';

const Login: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { authState } = useOktaAuth();

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            history.push(Routes.HOME);
        } else {
            Analytics.trackSignedInStarted();
        }
    }, [authState, history]);

    useEffect(() => {
        storePartnerEventSignUpCode(location);
    }, [location]);

    return (
        <Layout showPdsVersion={false} className="login__layout">
            <a href={ExternalLinks.landingPage}>
                <img src={logo} alt="Flip Insurance" className="login__logo" />
            </a>
            <h1 className="login__heading">{login.heading}</h1>
            <p className="login__subheading">{login.subheading}</p>
            <OktaSigninWidget className="login" />
        </Layout>
    );
};

export default Login;
