import { useOktaAuth } from '@okta/okta-react';
import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import blueArrow from '../../../assets/images/arrow-left-blue.svg';
import { useUser } from '../../../business-logic/context-provider/user-context';
import { PolicyItem } from '../../../business-logic/models/PoliciesResponse';
import Alert, { AlertTypes } from '../../../components/alert/Alert';
import BrandPurchaseButton from '../../../components/brand-purchase-button/BrandPurchaseButton';
import CancelCoverDrawerV2 from '../../../components/cancel-cover-drawer/CancelCoverDrawerV2';
import LoadingButton from '../../../components/loading-button/LoadingButton';
import LoadingSpinner from '../../../components/loading-spinner/LoadingSpinner';
import manageCoverContent from '../../../content/ui/screens/manage-cover/manageCoverContent';
import useStripe from '../../../hooks/usePaymentStripe';
import CoverCancelStatus from '../../../utils/constants/CoverCancelStatus';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import Routes from '../../../utils/Routes';
import getCancelStatus from '../utils/getCancelStatus';
import getCoverStatus from '../utils/getCoverStatus';
import getPaymentStatus from '../utils/getPaymentStatus';
import ManageCoverCard from './manage-cover-cards/ManageCoverCard';
import ManageCoverEmptyState from './manage-cover-cards/ManageCoverEmptyState';
import ManageCoverEventCard from './manage-cover-cards/ManageCoverEventCard';

import './ManageCoverTabList.scss';

interface ManageCoverTabListProps {
    policies: PolicyItem[];
    canLoadMore: boolean;
    loadMore: () => void;
    loading: boolean;
    isActive?: boolean;
    selectedPersonId: string;
    error: boolean;
    refreshCovers?: (personId: string) => void;
}

const ManageCoverTabList: FC<ManageCoverTabListProps> = ({
    policies,
    canLoadMore,
    loadMore,
    loading,
    isActive = false,
    selectedPersonId,
    error,
    refreshCovers,
}) => {
    const { userTimeZone, insurablePersons, userDetails } = useUser();
    const { authState } = useOktaAuth();
    const history = useHistory();
    const name =
        selectedPersonId === userDetails.personId
            ? 'You have'
            : `${insurablePersons.find((x) => x.personId === selectedPersonId)?.firstName} has`;
    const [coverIdToBeCancelled, setCoverIdToBeCancelled] = useState<string | null>(null);
    const goToCertificateOfCover = () => {
        // TODO: consider tracking
        history.push(Routes.ACCOUNT_CERTIFICATE_OF_COVER);
    };

    const { loading: billingLoading, manageProtection } = useStripe({
        basePath: import.meta.env.VITE_BASE_API_PATH || '',
        accessToken: authState?.accessToken?.accessToken,
    });
    const handleCancel = useCallback((coverId: string) => {
        setCoverIdToBeCancelled(coverId);
    }, []);

    return (
        <div className="manage-cover__tab-list">
            {!loading && !billingLoading && (!policies || policies.length === 0) && (
                <ManageCoverEmptyState name={name} isActive={isActive} />
            )}
            {loading && <LoadingSpinner />}
            {!loading &&
                !billingLoading &&
                policies.map((policy) => {
                    if (
                        (policy.mainCover?.coverTypeId && policy.mainCover.coverTypeId === CoverTypeId.SINGLE_V1) ||
                        (policy.mainCover?.coverTypeId && policy.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1)
                    ) {
                        return (
                            <ManageCoverCard
                                key={policy.insurancePolicyId}
                                status={getCoverStatus(policy)}
                                coverCode={policy.mainCover.coverCode}
                                title={policy?.mainCover?.coverName ?? ''}
                                startTime={policy?.mainCover?.startTime}
                                endTime={policy?.mainCover?.endTime}
                                acceptPaymentUntil={policy?.mainCover?.acceptPaymentUntil}
                                paymentStatus={getPaymentStatus(policy)}
                                renewalTime={policy?.mainCover?.nextCycleAt}
                                pdsVersion={policy?.PDSVersion}
                                destinations={policy.mainCover.destination?.destinations}
                                timeZone={policy?.policyTimezone ?? userTimeZone}
                                goToBillingCenter={manageProtection}
                                canCancel={getCancelStatus(policy) === CoverCancelStatus.CANCANCEL}
                                initiateCancellation={() => handleCancel(policy.mainCover.insuranceCoverId)}
                                className="manage-cover__tab__list__item__fade-in"
                            />
                        );
                    }
                    return (
                        <ManageCoverEventCard
                            key={policy.partnerEvent!.partnerEventId}
                            status={getCoverStatus(policy)}
                            coverCode={policy?.mainCover?.coverCode}
                            title={policy.partnerEvent!.eventName!}
                            startTime={policy.partnerEvent?.startTime ?? ''}
                            endTime={policy.partnerEvent?.endTime ?? ''}
                            pdsVersion={policy?.PDSVersion}
                            timeZone={policy.partnerEvent!.timeZone}
                        />
                    );
                })}
            {error && (
                <Alert
                    type={AlertTypes.ERROR}
                    message={manageCoverContent.unableToLoadCovers}
                    className="manage-cover__tab-list__error"
                />
            )}
            {canLoadMore && (
                <LoadingButton
                    label="Load more"
                    size="small"
                    onClick={loadMore}
                    variant="secondary"
                    width="full"
                    status={loading ? 'loading' : 'idle'}
                    className="manage-cover__tab-list__load-button"
                />
            )}
            <div className="manage-cover__tab-list__cta">
                {isActive ? (
                    <>
                        <h4 className="manage-cover__tab-list__cta__heading">Get more from Flip</h4>
                        <BrandPurchaseButton />
                    </>
                ) : (
                    <div className="button manage-cover__tab-list__cta__certificate">
                        <span className="manage-cover__tab-list__cta__certificate__description">
                            View your Cover Certificate to review your account’s full cover history
                        </span>
                        <button
                            type="button"
                            onClick={goToCertificateOfCover}
                            className="manage-cover__tab-list__cta__certificate__button"
                        >
                            <span className="manage-cover__tab-list__cta__certificate__button__content">
                                View Cover Certificate
                            </span>
                            <img
                                src={blueArrow}
                                alt=""
                                className="manage-cover__tab-list__cta__certificate__button__icon"
                            />
                        </button>
                    </div>
                )}
            </div>
            <CancelCoverDrawerV2
                firstName={insurablePersons.find((i) => i.personId === selectedPersonId)?.firstName ?? undefined}
                policies={policies}
                isOpen={!!coverIdToBeCancelled}
                preselectedCover={coverIdToBeCancelled}
                onClose={() => {
                    setCoverIdToBeCancelled(null);
                }}
                refreshCovers={() => refreshCovers!(selectedPersonId)}
            />
        </div>
    );
};

export default ManageCoverTabList;
