import { createMachine } from 'xstate';
import {
    setRecentlyAddedKid,
    setRecentlyAddedAdult,
    toggleInsuredPersonSelection,
    spawnKidDetailsFormMachine,
    spawnAdultDetailsFormMachine,
    setCreateAdultError,
    clearCreateAdultError,
    trackDependantAdded,
    trackAdultAdded,
} from '../actions/personsInsuredMachineActions';
import { PersonsInsuredMachineContextTypes } from '../context/personsInsuredMachineContext';
import { hasSelectedPerson } from '../guards/personsInsuredMachineGuards';
import { PersonsInsuredMachineServices, createDependant, createAdult } from '../services/personsInsuredMachineServices';
import { InsuredPerson } from '../../../cart-machine/context/cartMachineContext';

const PersonsInsuredMachine = createMachine(
    {
        id: 'PersonsInsuredMachine',
        initial: 'determineIfSelectionIsValid',
        schema: {
            context: {} as PersonsInsuredMachineContextTypes,
            events: {} as
                | { type: 'CONTINUE' }
                | { type: 'TOGGLE_INSURED_PERSON_SELECTION'; data: InsuredPerson }
                | { type: 'ADD_A_KID' }
                | { type: 'SAVE_KID' }
                | { type: 'CANCEL_ADD_A_KID' }
                | { type: 'ADD_AN_ADULT' }
                | { type: 'SAVE_ADULT' }
                | { type: 'RESET_ADD_ADULT_ERROR' }
                | { type: 'CANCEL_ADD_AN_ADULT' },
            services: {} as PersonsInsuredMachineServices,
        },
        tsTypes: {} as import('./personsInsuredMachine.typegen').Typegen0,
        context: {
            selectedPersons: [],
            allPersons: [],
            kidDetailsFormMachineRef: null,
            adultDetailsFormMachineRef: null,
            accessToken: '',
            createAdultError: null,
        },
        preserveActionOrder: true,
        states: {
            determineIfSelectionIsValid: {
                always: [{ cond: 'hasSelectedPerson', target: 'readyToContinue' }, { target: 'idle' }],
            },
            idle: {
                on: {
                    ADD_A_KID: {
                        target: 'addAKid',
                    },
                    ADD_AN_ADULT: {
                        target: 'addAnAdult',
                    },
                    TOGGLE_INSURED_PERSON_SELECTION: {
                        actions: 'toggleInsuredPersonSelection',
                        target: 'determineIfSelectionIsValid',
                    },
                },
            },
            readyToContinue: {
                on: {
                    ADD_A_KID: {
                        target: 'addAKid',
                    },
                    ADD_AN_ADULT: {
                        target: 'addAnAdult',
                    },
                    TOGGLE_INSURED_PERSON_SELECTION: {
                        actions: 'toggleInsuredPersonSelection',
                        target: 'determineIfSelectionIsValid',
                    },
                    CONTINUE: {
                        actions: 'notifyDependantsCreationComplete',
                    },
                },
            },
            addAKid: {
                initial: 'spawnKidDetailsFormMachine',
                states: {
                    spawnKidDetailsFormMachine: {
                        always: {
                            actions: 'spawnKidDetailsFormMachine',
                            target: 'ready',
                        },
                    },
                    ready: {
                        on: {
                            SAVE_KID: {
                                target: 'createDependant',
                            },
                            CANCEL_ADD_A_KID: {
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                        },
                    },
                    createDependant: {
                        invoke: {
                            src: 'createDependant',
                            onDone: {
                                actions: ['updateUserContext', 'setRecentlyAddedKid', 'trackDependantAdded'],
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                            onError: {
                                target: 'displayCreateDependantError',
                            },
                        },
                    },
                    displayCreateDependantError: {
                        on: {
                            SAVE_KID: {
                                target: 'createDependant',
                            },
                            CANCEL_ADD_A_KID: {
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                        },
                    },
                },
            },
            addAnAdult: {
                initial: 'spawnAdultDetailsFormMachine',
                states: {
                    spawnAdultDetailsFormMachine: {
                        always: {
                            actions: 'spawnAdultDetailsFormMachine',
                            target: 'ready',
                        },
                    },
                    ready: {
                        on: {
                            SAVE_ADULT: {
                                target: 'createAdult',
                            },
                            CANCEL_ADD_AN_ADULT: {
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                        },
                    },
                    createAdult: {
                        invoke: {
                            src: 'createAdult',
                            onDone: {
                                actions: [
                                    'clearCreateAdultError',
                                    'refreshUserContextAdults',
                                    'setRecentlyAddedAdult',
                                    'trackAdultAdded',
                                ],
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                            onError: {
                                actions: ['setCreateAdultError'],
                                target: 'displayCreateAdultError',
                            },
                        },
                    },
                    displayCreateAdultError: {
                        on: {
                            SAVE_ADULT: {
                                target: 'createAdult',
                            },
                            RESET_ADD_ADULT_ERROR: {
                                actions: 'clearCreateAdultError',
                                target: 'ready',
                            },
                            CANCEL_ADD_AN_ADULT: {
                                target: '#PersonsInsuredMachine.determineIfSelectionIsValid',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        guards: {
            hasSelectedPerson,
        },
        actions: {
            setCreateAdultError,
            setRecentlyAddedKid,
            setRecentlyAddedAdult,
            clearCreateAdultError,
            toggleInsuredPersonSelection,
            spawnKidDetailsFormMachine,
            spawnAdultDetailsFormMachine,
            trackDependantAdded,
            trackAdultAdded,
        },
        services: {
            createDependant,
            createAdult,
        },
    },
);

export default PersonsInsuredMachine;
