import differenceInYears from 'date-fns/differenceInYears';
import { AdultDetailsFormMachineContext } from '../context/adultDetailsFormMachineContext';
import isValidDate from '../../../../validation/isValidDate';
import getDate from '../../../../validation/getDate';
import isValidEmail from '../../../../validation/isValidEmail';

export const isFirstNameValid = (ctx: AdultDetailsFormMachineContext): boolean =>
    ctx.firstName.length >= ctx.config.nameMinLength;

export const isLastNameValid = (ctx: AdultDetailsFormMachineContext): boolean =>
    ctx.lastName.length >= ctx.config.nameMinLength;

export const isDateFilledIn = (ctx: AdultDetailsFormMachineContext): boolean =>
    ctx.dob.day.length > 0 && ctx.dob.month.length > 0 && ctx.dob.year.length === 4;

export const isValidDateFormat = (ctx: AdultDetailsFormMachineContext): boolean =>
    ctx.dob.year.length === 4 && isValidDate(ctx.dob.day, ctx.dob.month, ctx.dob.year);

export const isBelowMinAge = (ctx: AdultDetailsFormMachineContext): boolean =>
    differenceInYears(new Date(), getDate(ctx.dob.day, ctx.dob.month, ctx.dob.year)) < ctx.config.minAge;

export const isAboveMaxAge = (ctx: AdultDetailsFormMachineContext): boolean =>
    differenceInYears(new Date(), getDate(ctx.dob.day, ctx.dob.month, ctx.dob.year)) > ctx.config.maxAge;

export const isEmailFilledIn = (ctx: AdultDetailsFormMachineContext): boolean => ctx.email.length > 0;

export const isEmailValid = (ctx: AdultDetailsFormMachineContext): boolean => isValidEmail(ctx.email);

export const isPermissionCheckValid = (ctx: AdultDetailsFormMachineContext): boolean => {
    return ctx.purchaserPrivacyCheck;
};
export const isResidencyCheckValid = (ctx: AdultDetailsFormMachineContext): boolean => {
    return ctx.residencyCheck;
};

export const isSharingCheckValid = (ctx: AdultDetailsFormMachineContext): boolean => {
    return ctx.insuredPersonPrivacyCheck;
};
