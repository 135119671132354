import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import { PurchaseState } from '../../../../types/PurchaseState';
import CartStep from '../../constants/CartStep';

export enum InsuredPersonType {
    ACCOUNTHOLDER = 'accountholder',
    ADULT = 'adult',
    DEPENDANT = 'dependant',
}

export type InsuredPerson = {
    personId: string;
    type: InsuredPersonType;
    email?: string;
    firstName?: string;
};

export type CartMachineContextTypes = {
    purchaseState: PurchaseState | null;
    isExistingUser: boolean;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse | null;
    coverInformation: AllCoverInformation | null;
    insuredPersons: InsuredPerson[];
    currentStep: number;
    showStep: number | null;
    steps: CartStep[];
    availableInsuredPersonsTypes: InsuredPersonType[];
    withActivitySelection: boolean;
    withMultiAdultPurchase: boolean;
    activities: string[];
};
