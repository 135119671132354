import { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import Alert, { AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import selectCoverContent from '../../content/ui/screens/select-cover/selectCover';
import withContent from '../../hoc/with-content/withContent';
import usePathParams from '../../hooks/usePathParams';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import parseString from '../../utils/parseString';

import './SelectCover.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface SelectCoverProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectCover: FC<SelectCoverProps> = ({ content }) => {
    const history = useHistory();
    const { productGroups, products, initialised: productInitialised, loading: productLoading } = useProduct();
    const { setSelectedProductGroupParam } = usePathParams();

    const availableCategories = useMemo(() => {
        if (!productGroups || !products) return [];
        return productGroups.filter((group) =>
            group.options.some((cover) =>
                cover.coverCodes.some((code) => products.find((x) => x.productSpec.mainCoverType.coverCode === code)),
            ),
        );
    }, [productGroups, products]);

    const redirectToProductGroupPurchase = useCallback(
        (productGroup: string): void => {
            history.push({
                pathname: setSelectedProductGroupParam(Routes.COVER_INFORMATION, productGroup),
            });
        },
        [history],
    );

    useEffect(() => {
        Analytics.trackProductListViewed();
    }, []);

    return (
        <Layout title={content.heading} showBackButton showLoading={!productInitialised || productLoading}>
            <div className="cover-selection">
                {productInitialised && (!availableCategories || availableCategories.length === 0) && (
                    <Alert type={AlertTypes.ERROR} message={ErrorMessages.refreshOrContactUs} />
                )}
                {productInitialised &&
                    availableCategories.length > 0 &&
                    availableCategories
                        .sort((a, b) => a.position - b.position)
                        .map((group) => {
                            return (
                                <div className="cover" key={group.name}>
                                    <div className="cover__image-wrapper">
                                        <div className={`cover__highlight cover__highlight--${group.groupTagStyle}`}>
                                            <span className="cover__highlight__text">{group.groupTag}</span>
                                        </div>
                                        <img src={group.image} alt="" className="cover__image" />
                                    </div>
                                    <div className="cover__content">
                                        <div className="cover__content__text">
                                            <div className="cover__content__title">
                                                <h3>{group.name}</h3>
                                                <div className="cover__content__title__price">
                                                    <small>{group.pricePrefix}</small>${group.price}/{group.priceUnit}
                                                </div>
                                            </div>
                                            <span className="cover__content__text__blurb">
                                                {parseString(group.description)}
                                            </span>
                                        </div>
                                        <Button
                                            type="button"
                                            width="full"
                                            size="small"
                                            onClick={() => {
                                                Analytics.trackProductViewed();
                                                redirectToProductGroupPurchase(group.id);
                                            }}
                                            variant="primary"
                                            label={common.select}
                                            className="cover__content__cta"
                                        />
                                    </div>
                                </div>
                            );
                        })}
            </div>
        </Layout>
    );
};

export default withContent(SelectCover, contentMap, selectCoverContent);
