import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import CartStep, { CartStepsWithKids, CartStepsWithoutKids } from '../../constants/CartStep';
import { Typegen0 } from '../cartMachine.typegen';
import { CartMachineContextTypes, InsuredPerson, InsuredPersonType } from '../context/cartMachineContext';
import { CHECKOUT_EVENT } from '../../../../analytics/AnalyticsConstants';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    CartMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            products: ProductResponse[];
            productGroups: ProductGroupsResponse | null;
            coverInformation: AllCoverInformation | null;
            withActivitySelection: boolean;
            withMultiAdultPurchase: boolean;
        };
    }
>({
    productGroups: (ctx, event) => event.data.productGroups,
    products: (ctx, event) => {
        return event.data.products;
    },
    coverInformation: (ctx, event) => {
        return event.data.coverInformation;
    },
    withActivitySelection: (ctx, event) => {
        return event.data.withActivitySelection;
    },
    withMultiAdultPurchase: (ctx, event) => {
        return event.data.withMultiAdultPurchase;
    },
}) as any;

export const setDependantsAsAvailableInsuredType = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setDependantsAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types: InsuredPersonType[] = [...ctx.availableInsuredPersonsTypes, InsuredPersonType.DEPENDANT];
        return types;
    },
}) as any;

export const updateActivitiesSelectionToCart = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['updateActivitiesSelectionToCart']; data: string[] }
>({
    activities: (ctx, event) => event.data,
}) as any;

export const setAccountHolderAsAvailableInsuredType = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setAccountHolderAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types: InsuredPersonType[] = [...ctx.availableInsuredPersonsTypes, InsuredPersonType.ACCOUNTHOLDER];
        return types;
    },
}) as any;

export const setAdultsAsAvailableInsuredType = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setAdultsAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types: InsuredPersonType[] = [...ctx.availableInsuredPersonsTypes, InsuredPersonType.ADULT];
        return types;
    },
}) as any;

export const setInsuredPersons = assign<
    CartMachineContextTypes,
    { type: 'PERSONS_INSURED_COMPLETE'; data: InsuredPerson[] }
>({
    insuredPersons: (ctx, event) => event.data,
}) as any;

export const setCartStepsWithoutKids = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setCartStepsWithoutKids'] }
>({
    steps: (ctx) => {
        return ctx.withActivitySelection
            ? CartStepsWithoutKids
            : CartStepsWithoutKids.filter((x) => x !== CartStep.ACTIVITIES);
    },
}) as any;

export const setCartStepsWithKids = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setCartStepsWithKids'] }
>({
    steps: (ctx) => {
        return ctx.withActivitySelection
            ? CartStepsWithKids
            : CartStepsWithKids.filter((x) => x !== CartStep.ACTIVITIES);
    },
}) as any;

export const setProfileStep = assign<
    CartMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['setProfileStep'] }
>({
    currentStep: () => CartStep.PROFILE,
}) as any;

export const setActivitiesStep = assign<
    CartMachineContextTypes,
    { type: 'TOGGLE_ACTIVITIES' } | { type: EventsCausingActions['setActivitiesStep'] }
>({
    currentStep: () => CartStep.ACTIVITIES,
}) as any;

export const setPersonsInsuredStep = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setPersonsInsuredStep'] }
>({
    currentStep: () => {
        return CartStep.WHOS_COVERED;
    },
}) as any;

export const setReviewCoverStep = assign<
    CartMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'PERSONS_INSURED_COMPLETE'> }
    | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    currentStep: () => CartStep.REVIEW_COVER,
}) as any;

export const setPaymentStep = assign<CartMachineContextTypes, { type: EventsCausingActions['setPaymentStep'] }>({
    currentStep: () => CartStep.PAYMENT,
}) as any;

export const showProfileStep = assign<
    CartMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['showProfileStep'] }
>({
    showStep: () => CartStep.PROFILE,
}) as any;

export const showActivitiesStep = assign<
    CartMachineContextTypes,
    { type: 'TOGGLE_ACTIVITIES' } | { type: EventsCausingActions['showActivitiesStep'] }
>({
    showStep: () => CartStep.ACTIVITIES,
}) as any;

export const showPersonsInsuredStep = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['showPersonsInsuredStep'] }
>({
    showStep: () => {
        return CartStep.WHOS_COVERED;
    },
}) as any;

export const showPaymentStep = assign<CartMachineContextTypes, { type: EventsCausingActions['showPaymentStep'] }>({
    showStep: () => {
        return CartStep.PAYMENT;
    },
}) as any;

export const showReviewCoverStep = assign<
    CartMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'PERSONS_INSURED_COMPLETE'> }
    | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    showStep: () => CartStep.REVIEW_COVER,
}) as any;

// // Analytics

export const trackProfileStepViewed = (): void => {
    Analytics.trackCheckoutStepViewed(CHECKOUT_EVENT.YOUR_DETAILS);
};

export const trackActivitiesStepViewed = (): void => {
    Analytics.trackCheckoutStepViewed(CHECKOUT_EVENT.ACTIVITY);
};

export const trackPersonsInsuredStepViewed = (): void => {
    Analytics.trackCheckoutStepViewed(CHECKOUT_EVENT.PERSONS_COVERED);
};

export const trackReviewCoverStepViewed = (): void => {
    Analytics.trackCheckoutStepViewed(CHECKOUT_EVENT.REVIEW_PURCHASE);
};

export const trackPaymentStepViewed = (): void => {
    Analytics.trackCheckoutStepViewed(CHECKOUT_EVENT.PAYMENT);
};

export const trackProfileStepCompleted = (): void => {
    Analytics.trackCheckoutStepCompleted(CHECKOUT_EVENT.YOUR_DETAILS);
};

export const trackActivitiesStepCompleted = (): void => {
    Analytics.trackCheckoutStepCompleted(CHECKOUT_EVENT.ACTIVITY);
};

export const trackPersonsInsuredStepCompleted = (ctx: any, event: any): void => {
    Analytics.trackCheckoutStepCompleted(CHECKOUT_EVENT.PERSONS_COVERED, {
        insuredPersons: [...new Set(event.data.map((x: InsuredPerson) => x.type))],
    });
};

export const trackReviewCoverStepCompleted = (): void => {
    Analytics.trackCheckoutStepCompleted(CHECKOUT_EVENT.REVIEW_PURCHASE);
};

export const trackPaymentStepCompleted = (): void => {
    Analytics.trackCheckoutStepCompleted(CHECKOUT_EVENT.PAYMENT);
};

export const trackCheckoutCompleted = (): void => {
    Analytics.trackCheckoutCompleted();
};
