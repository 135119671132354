export interface AdultDetailsFormMachineContext {
    firstName: string;
    lastName: string;
    dob: {
        day: string;
        month: string;
        year: string;
        error: string;
    };
    email: string;
    emailError: string;
    purchaserPrivacyCheck: boolean;
    insuredPersonPrivacyCheck: boolean;
    residencyCheck: boolean;
    config: {
        nameMinLength: number;
        minAge: number;
        maxAge: number;
    };
}

export const initialContext: AdultDetailsFormMachineContext = {
    firstName: '',
    lastName: '',
    dob: {
        day: '',
        month: '',
        year: '',
        error: '',
    },
    email: '',
    emailError: '',
    purchaserPrivacyCheck: false,
    insuredPersonPrivacyCheck: false,
    residencyCheck: false,
    config: {
        nameMinLength: 1,
        minAge: 18,
        maxAge: 64,
    },
};
