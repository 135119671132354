import { createMachine } from 'xstate';
import { CartErrorDisplay, CartInstanceResponse } from '../../../../../business-logic/models/Cart';
import { resetCartError, setCartError, setCartTotal, setCoversToDisplay } from '../actions/reviewCoverMachineActions';
import { ReviewCoverMachineContextTypes } from '../context/reviewCoverMachineContext';
import { ReviewCoverMachineMachineServices, setMetadata } from '../services/reviewCoverMachineService';

const ReviewCoverMachine = createMachine(
    {
        id: 'ReviewCoverMachine',
        initial: 'createCart',
        schema: {
            services: {} as ReviewCoverMachineMachineServices,
            context: {} as ReviewCoverMachineContextTypes,
            events: {} as
                | { type: 'CART_TRY_AGAIN' }
                | { type: 'CART_CREATED'; data: CartInstanceResponse }
                | { type: 'CART_CREATE_ERROR'; data: CartErrorDisplay }
                | { type: 'METADATA_TRY_AGAIN' },
        },
        context: {
            accessToken: '',
            cartError: null,
            purchaseState: null,
            products: [],
            productGroups: [],
            coverInformation: null,
            userTimeZone: '',
            cartTotal: 0,
            cartItemsByCoverCode: [],
        },
        tsTypes: {} as import('./reviewCoverMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            createCart: {
                entry: ['openCart'],
                tags: ['initialising'],
                on: {
                    CART_TRY_AGAIN: {
                        actions: ['resetCartError'],
                        target: 'createCart',
                    },
                    CART_CREATE_ERROR: {
                        actions: ['setCartError'],
                        target: 'displayCartError',
                    },
                    CART_CREATED: {
                        actions: ['setCoversToDisplay', 'setCartTotal'],
                        target: 'updateMetadata',
                    },
                },
            },
            updateMetadata: {
                invoke: {
                    src: 'setMetadata',
                    onDone: [
                        {
                            target: 'readyToContinue',
                        },
                    ],
                    onError: [
                        {
                            target: 'displayMetadataError',
                        },
                    ],
                },
            },
            readyToContinue: {
                type: 'final',
            },
            displayMetadataError: {
                tags: ['error'],
                on: {
                    METADATA_TRY_AGAIN: {
                        target: 'updateMetadata',
                    },
                },
            },
            displayCartError: {
                tags: ['error'],
                on: {
                    CART_TRY_AGAIN: {
                        actions: ['resetCartError'],
                        target: 'createCart',
                    },
                },
            },
        },
    },
    {
        actions: {
            setCartError,
            resetCartError,
            setCartTotal,
            setCoversToDisplay,
        },
        services: {
            setMetadata,
        },
    },
);

export default ReviewCoverMachine;
