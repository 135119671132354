import LocalizedStrings from 'react-localization';

const adultDetailsFormContent = new LocalizedStrings({
    en: {
        firstName: {
            label: 'First name',
            placeholder: 'John',
        },
        lastName: {
            label: 'Last name',
            placeholder: 'Citizen',
        },
        gender: {
            label: 'Select gender',
        },
        dropdownPlaceholder: 'Please select',
        dob: {
            label: 'Their date of birth',
        },
        dobError: {
            errorInvalidDate: 'Sorry, the date doesn’t look right',
            errorMustBeOver18: 'Sorry, the adult needs to be 18 or over to get covered with Flip Insurance',
            errorMustBeUnder65: 'Sorry, the adult needs to be 64 or under to get covered with Flip Insurance',
        },
        email: {
            label: 'Their email address',
            placeholder: 'name@email.com',
        },
        emailError: "Sorry, that email doesn't look right",
        residencyCheck: {
            label: '{firstName} lives in Australia',
        },
        // permissionCheck
        purchaserPrivacyCheck: {
            label: 'Flip can share my full name with {firstName} to confirm I purchased their cover',
        },
        // sharingCheck
        insuredPersonPrivacyCheck: {
            label: '{firstName} has given me permission to share their email address and personal information so that Flip can deliver their policy details via email',
        },
        conflictAccountHolderEmail: {
            errorMessage:
                "Please enter {firstName}'s email address as we need it to create their cover. You can't use your email as another person's identifier.",
        },
        conflictExistingAliasEmail: {
            errorMessage:
                'Email address entered already exists on your account for {firstName}. Please select them from the list above.',
        },
        conflictExistingEmail: {
            errorMessage:
                'Email address entered already exists on your account. Please select them from the list above.',
        },
    },
});

export default adultDetailsFormContent;
